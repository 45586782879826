/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import { List } from "gatsby-theme-form/src/components/NewsletterSubscriptionForm/Component";
import React, { memo } from "react";
import { loadable } from "shared/helpers/loadableComponent";
import { jsx } from "theme-ui";

import { AllNewsletterListsQuery } from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

const NewsletterSubscriptionForm = loadable(() => import("gatsby-theme-form"), {
  resolveComponent: (components) => components.NewsletterSubscriptionForm,
});

interface Props extends WidgetProps {
  widget: Extract<
    WidgetUnion,
    { __typename: "WidgetNewsletterSubscriptionForm" }
  >;
}

const NewsletterSubscriptionFormWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const data = useStaticQuery<AllNewsletterListsQuery>(graphql`
    query AllNewsletterLists {
      allNewsletterList {
        nodes {
          id
          name
        }
      }
    }
  `);

  // If no newsletter lists were selected, display all lists.
  const lists = (
    (widget.newsletterSubscriptionFormShape?.lists?.length
      ? widget.newsletterSubscriptionFormShape?.lists
      : data.allNewsletterList.nodes) as List[]
  ).sort((list1, list2) => (list1.name || "")?.localeCompare(list2.name || ""));

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <NewsletterSubscriptionForm
        formId={widget.formId}
        formUrl={widget.newsletterSubscriptionFormShape?.endpoint}
        reCaptchaKey={widget.newsletterSubscriptionFormShape?.siteKey}
        lists={lists}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetNewsletterSubscriptionForm on WidgetNewsletterSubscriptionForm {
    id
    formId
    newsletterSubscriptionFormShape {
      endpoint
      siteKey
      lists {
        id
        name
      }
    }
    __typename
  }
`;

export default memo(NewsletterSubscriptionFormWidget);
